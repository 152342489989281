<template>
  <div class="one-author" @click="goToUserDetail">
    <img :src="author.img" alt class="imge" />
    <div class="info">
      <div>
        <span class="name">{{author.name}}</span>

        <span class="v-icons" v-if="author.enterprise==1"></span>
        <span class="v-icon" v-else></span>
      </div>
      <div class="intro" title="author.intro">{{author.intro}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true
    }
  },
  methods: {
    //打开用户详情
    goToUserDetail() {
      this.$router.push({
        path: "/authorXX",
        query: {
          id: this.author.id
        }
      });
    }
  }
};
</script>

<style scoped>
.imge {
  /* border: 1px solid red; */
  width: 50px;
  height: 50px;
  display: inline-block;
}
.one-author {
  text-align: left;
  padding: 28px 40px;
  cursor: pointer;
}
.one-author:hover {
  background-color: cornsilk;
}

.one-author .info {
  display: inline-block;
  vertical-align: top;
  width: 256px;
  margin-left: 19px;
  padding-top: 10px;
}
.one-author img {
  width: "117px";
  height: "117px";
  border-radius: 50%;
}
.one-author .info .name {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
}
.one-author .info .intro {
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  word-break: break-all;
}
.v-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url(~@/assets/icon/v-icon.png);
  background-repeat: no-repeat;
}
.v-icons {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-size: 100% 100%;
  background-image: url("~@/assets/LSdv01.png");
  background-repeat: no-repeat;
}
.one-author .info .v-icon {
  margin-left: 6px;
}
</style>