<template>
  <div class="container">
    <div class="content">
      <div>
        <div
          :class="{'left':index%2==0,'right':index%2==1}"
          v-for="(author,index) in  authors"
          :key="index"
        >
          <V-OneAuthor :author="author"></V-OneAuthor>
        </div>
        <div
          v-if="authors.length==0"
          style="font-size: 20px;margin-top:100px; color:#626262"
        >抱歉未找到相关结果</div>
        <div v-if="authors.length%2==1" class="right" style="background-color:transparent"></div>
        <div class="clear-float"></div>
      </div>
      <div class="this-page">
        <div class="page-nav">
          <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="hidePageNav"
            :total="totlePage"
            :page-size="pageSize"
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="currentPage"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OneAuthor from "./one-author/index.vue";
import { DELETE, QUERY, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      hidePageNav: true,
      searWord: "",
      pageNum: 1,
      pageSize: 15,
      totlePage: 0,
      authors: []
    };
  },
  created: function() {
    this.init();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.searchWord != from.query.searchWord) {
        this.init(); //重新加载数据
      }
    }
  },
  methods: {
    //初始化
    init() {
      this.searchWord = this.$route.query.searchWord;
      this.searchInfo(this.pageSize, 0);
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    async searchInfo(limit, offest) {
      this.authors = [];
      let info = null;
      let aa = 1;
      //   let info=  await  QUERY("post","",'  User(limit: '+limit+', offset: '+offest+', where: {name: {_like: "%'+ this.searchWord+'%"}}) {    id    img    name    concernNum    Articles(limit: 1, order_by: {id: desc}) {      title      id      intro    }  }  User_aggregate(where: {name: {_like: "%'+ this.searchWord+'%"}}) {    aggregate {      count    }  }');
      if (
        this.searchWord == null ||
        this.searchWord == "" ||
        this.searchWord == "企业"
      ) {
        info = await QUERYED(
          "post",
          "",
          `  PCUsers(where: {enterprise: {_eq: "${aa}"}}) {    id    img    name   intro    enterprise   }  User_aggregate (where: {enterprise: {_eq: "${aa}"}}) {    aggregate {      count    }  }`
        );
        // limit:${limit}, offset:${offest},
      } else {
        info = await QUERY(
          "post",
          "",
          "  PCUsers(limit: " +
            limit +
            ", offset: " +
            offest +
            ', where: {name: {_like: "%' +
            this.searchWord +
            '%"}}) {    id    img    name   intro    enterprise   }  User_aggregate(where: {name: {_like: "%' +
            this.searchWord +
            '%"}}) {    aggregate {      count    }  }'
        );
      }
      this.authors.splice(0, this.authors.length);
      for (let i = 0; i < info.data.PCUsers.length; i++) {
        this.authors.push({
          id: info.data.PCUsers[i].id,
          img: this.api.LoginURL.concat(info.data.PCUsers[i].img),
          name: info.data.PCUsers[i].name,
          intro: info.data.PCUsers[i].intro,
          enterprise: info.data.PCUsers[i].enterprise
        });
      }
      // this.totlePage=info.data.HeadlineComment_aggregate.aggregate.count;
    }
  },
  components: {
    "V-OneAuthor": OneAuthor
  }
};
</script>

<style scoped>
.clear-float {
  clear: both;
}
.container {
  text-align: center;
  background: #f7f7f7;
}
.content {
  width: 1043px !important;
  text-align: center;
  display: inline-block;
}
.content > div {
  width: 100%;
  text-align: center;
}
.content .left,
.content .right {
  width: 490px;
  display: inline-block;
  vertical-align: top;
  height: 155px;
  background: white;
}
.content .right {
  margin-left: 6px;
}
.content .this-page {
  margin-top: 80px;
}
</style>